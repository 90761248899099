import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  root: {
    height: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    background: '#424242',
    color: '#fff',
    '& .MuiTableCell-body, .MuiTableCell-root, .MuiTableCell-head, .MuiButtonBase-root':{
      color: '#fff',
    },
    '& .MuiTableCell-root':{
      borderBottom: '1px solid #5c5c5c'
    }
  },
  pdfContainer: {
    flexGrow: 1,
    position: 'relative',
    width: '100%',
    zIndex: 9999,
  },
  pdfWrapper: {
    position: 'relative',
    top: '4px',
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    flexGrow: 1,
    height: '91%',
    position: 'absolute',
    overflow: 'hidden scroll',
    background: '#424242',
    top: 0,
    left: 0,
    width: '100%'
  },
  outsource: {
    width: '100%',
    border: '0px',
  },
  fullView: {
    width: '100% !important',
  },
  close: {
    position: 'absolute',
    right: '0',
    zIndex: 11,
    top: '-3px',
    fontSize: '20px',
    cursor: 'pointer',
  },
  rootContainer:{
    '& td':{
      verticalAlign: 'top'
    }
  },
  fixedWidth:{
    width:180
  },
  red: {
    color: '#e60000 !important'
  },
  table:{
    tableLayout: 'fixed'
  },
  tablebg:{
    
  },
  cellHeading:{
    whiteSpace: 'nowrap',
    padding: '19px 19px 17px 19px', 
    background: '#292929', 
    '& .MuiTypography-body2':{
      fontSize: '1rem'
    }    
  },
  blue:{
    color: '#228DE8',
    fontWeight: 'bold' 
  },
  marginHeading: {
    '& .MuiTypography-body2':{
      marginBottom: 5,
      '&:last-child':{
        marginBottom: 0
      }
    }
  },
  marginBottom: {
    marginBottom: 5,
  }
}))
