import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  pdfContainer: {
    position: 'relative',
    zIndex: 9999,
    width: '100%',
    height: '100%',
    /* display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',  */
    '& .MuiTabScrollButton-root':{
      width: '20px'
    },
    '& .MuiTab-root':{
      minWidth: 'inherit', 
    },
  },
  pdfWrapper: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'rgb(82, 86, 89)',
  },
  scrollbar: {
    flexGrow: 1,
    overflow: 'hidden',
    height: '100%',
    width: '100%',
  },
  container: {
    flexGrow: 1,
    height: '100%',
    border: '1px solid #363636',
    position: 'relative',
    overflow: 'hidden',
  },
  outsource: {
    width: '100%',
    border: '0px',
  },
  fullView: {
    width: '100% !important',
  },
  close: {
    position: 'absolute',
    zIndex: 11,
    right: 0,
    top: '1px',
    cursor: 'pointer',
  }, 
  tabs: {
    backgroundColor: '#292929',
    '& .MuiTab-root':{
      minWidth: '90px'
    },
    minHeight: '36px !important',
    display: 'flex',
    '& .MuiTab-root': {
      fontSize: '1rem',
      minHeight: '32px !important',
    }
  },
  tab: {
    minHeight: '32px !important',
  },
  fullscreenBtn: {
    position: 'absolute',
    top: '8px',
    right: 0,
    zIndex: 1
  }
}))
